
import React from "react";
import CreateDeckContainer from '../../../Dashboard/myDecks/containers/CreateDeckContainer';


const CreateDeckPage = ({ location }) => {

  return (
    <CreateDeckContainer location={location}/>
  )
}

export default CreateDeckPage;
