import React from 'react';
import { Link } from 'gatsby';
import styles from './Breadcrumbs.module.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const Breadcrumbs = ({ crumbsArr }) => {
  return (
    <div className={styles.breadcrumbs}>
      {crumbsArr.map((crumb, i) => {
        return (
          <span>
            <CrumbLink crumb={crumb} i={i} crumbsArr={crumbsArr}>
              <span>{crumb.title} </span>
            </CrumbLink>
            <span style={{ margin: '0px 3px' }}>
              {i < crumbsArr.length - 1 ? (
                <ArrowForwardIcon fontSize="inherit" />
              ) : (
                ''
              )}
            </span>
          </span>
        );
      })}
    </div>
  );
};

const CrumbLink = ({ crumb, i, children, crumbsArr }) => {
  if (i < crumbsArr.length - 1) {
    return (
      <Link
        style={{ textDecoration: 'none', color: 'rgb(196, 196, 196)' }}
        to={crumb.to}
      >
        {children}
      </Link>
    );
  } else {
    return <span style={{ marginLeft: '3px' }}>{children}</span>;
  }
};

export default Breadcrumbs;
