import { api_base_url } from '../../../../../global_data';
import axios from 'axios';
import { navigate } from 'gatsby';

const onSubmitNewDeck = async ({
  state,
  userGlobalState,
  setFeedbackState
}) => {
  try {
    const artistId = userGlobalState.artist._id;

    setFeedbackState({
      isActive: true,
      isLoading: true
    });

    if (!state.title) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'The deck title is empty'
      });
    } else if (!state.coverImgUrl) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: 'No image was uploaded'
      });
    } else {
      const formData = new FormData();

      formData.append('coverImgUrl', state.coverImgUrl);

      formData.append(
        'data',
        JSON.stringify({
          artist: artistId,
          ...state
        })
      );

      await axios({
        url: `${api_base_url}/decks`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });

      navigate('/dashboard/myDecks?artistId=' + artistId);

      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: false,
        message: 'Created'
      });
    }
  } catch (err) {
    console.log('err', err);
    setFeedbackState({
      isActive: true,
      isError: true,
      isLoading: false,
      message: 'Error'
    });
  }
};

export default onSubmitNewDeck;

// setFeedbackState({
//     isActive: true,
//     isLoading: true
// })
// setFeedbackState({
//     isActive: true,
//     isError: false,
//     isLoading: false,
//     message: 'Saved'
// })
// setFeedbackState({
//     isActive: true,
//     isError: true,
//     isLoading: false,
//     message: validation_object.message
// })
