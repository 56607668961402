
import React from "react";
import PageHeader from "../../lib/components/PageHeader/PageHeader";
import MyDeckForm from "../components/MyDeckForm";
import Breadcrumbs from "../../lib/components/Breadcrumbs/Breadcrumbs";
import { Helmet } from "react-helmet"


const CreateDeck = ({ onSubmit, userGlobalState, data, onChangeField }) => {   

    return (
        <>
            <Helmet>
                <title>Create Deck</title>
            </Helmet>
            <PageHeader>
                <Breadcrumbs crumbsArr={[{title: 'My Decks', to: `/dashboard/myDecks?artistId=${userGlobalState.artist._id}`}, {title: 'New Deck'}]}/>
            </PageHeader>
            <MyDeckForm 
                value={data} 
                onSubmit={onSubmit} 
                onChangeField={onChangeField} 
                cta='Create'
            />
        </>
    );
};

export default CreateDeck;
