
import React, { useContext } from "react";
import useSetState from '../../../utils/useSetState';
import CreateDeck from '../../myDecks/CreateDeck';
import onSubmitNewDeck from './utils/onSubmitNewDeck';
import { UserContext } from '../../../Context/UserContext/UserContext';
import { FeedbackContext } from '../../../Context/FeedbackContext/FeedbackContext';
import Navbar from "../../../lib/components/Navbar/Navbar";
import CenterContainer from "../../../lib/components/CenterContainer/CenterContainer";
import DashboardWrapper from "../../DashboardWrapper";
import { cloneDeep } from 'lodash';



const CreateDeckContainer = ({ location }) => {

    const [state, setState] = useSetState({});

    const { userGlobalState } = useContext(UserContext);
    const { setFeedbackState } = useContext(FeedbackContext);

    const onSubmit = (e) => {
        e.preventDefault();
        onSubmitNewDeck({ state, userGlobalState,setFeedbackState })
    } 

    const onChangeField = ({ fieldName }, e) => {
        const stateClone = cloneDeep(state);
        stateClone[fieldName] = e.target.value;
        setState(stateClone);
    }
    

    return (
        <>
            <Navbar/>
            <CenterContainer>
                <DashboardWrapper>
                    <CreateDeck 
                        onSubmit={onSubmit} 
                        userGlobalState={userGlobalState}
                        data={state}
                        onChangeField={onChangeField}
                    />
                </DashboardWrapper>
            </CenterContainer>
           
        </>
       
    );
};

export default CreateDeckContainer;
